<template>
  <div>
    <v-bottom-navigation color="info" horizontal dark>
      <v-btn to="/admin/sys_config_collegemanpower">
        <span>ตั้งค่าระบบ </span>
        <v-icon>mdi-wrench</v-icon>
      </v-btn>

      <v-btn to="/admin/personnel_etc">
        <span>อัตรากำลังสถานศึกษา </span>
        <v-icon>mdi-numeric-1-box</v-icon>
      </v-btn>

      <v-btn to="/admin/power_rate/rate_work_college">
        <span>จำนวนนักเรียน </span>
        <v-icon>mdi-numeric-2-box</v-icon>
      </v-btn>

      <v-btn to="/admin/rate_work_g">
        <span>รายงานอัตรากำลังประจำปี สถานศึกษา</span>
        <v-icon>mdi-numeric-3-box</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="รายงานอัตรากำลังประจำปี สถานศึกษา"
        class="px-5 py-3"
      >
        <v-row>
          <v-col cols="12" md="4">
            <v-card>
              <v-select
                filled
                v-model="years_select"
                :items="year_ss"
                item-value="year_ss"
                label="ปีที่ :"
                @change="rate_work_gQueryTime()"
              ></v-select>
              <v-list dense>
                <v-subheader>REPORTS</v-subheader>
                <v-list-item-group v-model="selectedItem" color="primary">
                  <v-list-item
                    v-for="(item, i) in items"
                    :key="i"
                    :href="item.link + years_select"
                    target="_blank"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card>
              <v-select
                filled
                v-model="years_select"
                :items="year_ss"
                item-value="year_ss"
                label="ปีที่ :"
                @change="rate_work_gQueryTime()"
              ></v-select>
              <v-list dense>
                <v-subheader>สถานะการดำเนินงาน</v-subheader>
                <v-list-item-group v-model="selectedItem" color="primary">
                  <v-list-item
                    v-for="(item, i) in itemsStatus"
                    :key="i"
                    :href="item.link + years_select"
                    target="_blank"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card class="pa-5">
              <v-btn @click="checkConfirm()" rounded color="green" block>
                <v-icon>mdi-checkbox-marked-circle</v-icon>
                สถานศึกษาดำเนินการเรียบร้อย
              </v-btn>
              <v-btn @click="checkSave()" rounded color="warning" block>
                <v-icon>mdi-alert-circle</v-icon> สถานศึกษาอยู่ระห่วางดำเนินการ
              </v-btn>
              <v-btn @click="checkNull()" rounded color="red" block>
                <v-icon>mdi-alert-outline</v-icon> สถานศึกษายังไม่ได้ดำเนินการ
              </v-btn>

              <v-btn @click="rate_work_gQueryTime()" rounded color="info" block>
                <v-icon>mdi-alert-outline</v-icon> แสดงทั้งหมด
              </v-btn>

            </v-card>
          </v-col>
        </v-row>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
          filled
          class="mb-2"
        />
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="rate_work_gs"
          :search="search"
        >
          <template v-slot:item.rate_work_g_status="{ item }">
            <v-chip
              dark
              color="green"
              v-if="item.rate_work_g_status === 'Confirm'"
            >
              <v-icon>mdi-checkbox-marked-circle</v-icon>
            </v-chip>
            <v-chip
              dark
              color="warning"
              v-else-if="item.rate_work_g_status === 'Save'"
            >
              <v-icon>mdi-alert-circle</v-icon>
            </v-chip>

            <v-chip dark color="red" v-else>
              <v-icon>mdi-alert-outline</v-icon>
            </v-chip>
          </template>

          <template v-slot:item.room_std_vc="{ item }">
            {{ parseFloat(item.room_std_vc).toFixed(2) }}
          </template>

          <template v-slot:item.room_std_hvc="{ item }">
            {{ parseFloat(item.room_std_hvc).toFixed(2) }}
          </template>

          <template v-slot:item.status_booking="{ item }">
            <v-chip dark color="warning" v-if="item.status_booking">
              {{ item.status_booking }}
            </v-chip>
            <v-chip dark color="green" v-else>
              ว่าง
            </v-chip>
          </template>

                   
          <template v-slot:item.action_s="{ item }">
            <v-icon
              large
              color="red"
              @click.stop="
                rate_work_gDelete(
                  item.rate_work_g_year,
                  item.rate_work_g_college_code
                )
              "
            >
              mdi-close-circle
            </v-icon>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!--addrate_work_gdialog  -->
      <v-layout row justify-center>
        <v-dialog v-model="addrate_work_gdialog" persistent max-width="50%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              icon="mdi-account-multiple"
              title="เพิ่มข้อมูลประเภทสถานศึกษา"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text>
              <v-form ref="addrate_work_gform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <v-autocomplete
                        :items="colleges"
                        item-text="college_name"
                        item-value="college_code"
                        v-model="addrate_work_g.college_code"
                        label="สถานศึกษา"
                        required
                        :rules="[v => !!v || '']"
                      >
                      </v-autocomplete>
                    </v-flex>
                    <v-flex md12>
                      <v-text-field
                        label="เลขที่ตำแหน่ง"
                        v-model="addrate_work_g.id_position"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12>
                      <v-flex md6>
                        <v-autocomplete
                          v-model="addrate_work_g.position"
                          :items="userstatus"
                          item-text="user_status_name"
                          item-value="user_status_name"
                          label="ตำแหน่ง"
                          required
                          :rules="[v => !!v || '']"
                        ></v-autocomplete>
                      </v-flex>
                    </v-flex>
                    <v-flex md12>
                      <v-text-field
                        label="กรณี"
                        v-model="addrate_work_g.case_vacancy"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="addrate_work_gdialog = false"
                rounded
                ><v-icon dark>mdi-close</v-icon> ยกเลิก</v-btn
              >
              <v-btn
                large
                color="success"
                @click.stop="addrate_work_gSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model deleterate_work_gdialog -->
      <v-layout>
        <v-dialog v-model="deleterate_work_gdialog" persistent max-width="40%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ลบข้อมูลประเภทสถานศึกษา"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text class="text_google">
              <v-card>
                <v-card-text>
                  <v-form ref="deleterate_work_gform" lazy-validation>
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          ยืนยันการลบข้อมูล <br />
                          {{
                            editrate_work_g.college_name +
                              " : " +
                              editrate_work_g.rate_work_g_year
                          }}
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="deleterate_work_gdialog = false"
                ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                large
                color="red darken-3"
                @click.stop="deleterate_work_gSubmit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model deleterate_work_gPersondialog -->
      <v-layout>
        <v-dialog
          v-model="deleterate_work_gPersondialog"
          persistent
          max-width="40%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ลบข้อมูลบุคคลสถานศึกษา"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text class="text_google">
              <v-card>
                <v-card-text>
                  <v-form ref="deleterate_work_gPersonform" lazy-validation>
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          ยืนยันการลบข้อมูล <br />
                          {{
                            editrate_work_g.college_name +
                              " : " +
                              editrate_work_g.rate_work_g_year
                          }}
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="deleterate_work_gPersondialog = false"
                ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                large
                color="red darken-3"
                @click.stop="deleterate_work_gPersonSubmit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model editrate_work_gdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="editrate_work_gdialog" persistent max-width="80%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูลผู้ใช้งานระบบ"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editrate_work_gform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <v-autocomplete
                        :items="colleges"
                        item-text="college_name"
                        item-value="college_code"
                        v-model="editrate_work_g.college_code"
                        label="สถานศึกษา"
                        required
                        :rules="[v => !!v || '']"
                      >
                      </v-autocomplete>
                    </v-flex>
                    <v-flex md12>
                      <v-text-field
                        label="เลขที่ตำแหน่ง"
                        v-model="editrate_work_g.id_position"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12>
                      <v-flex md6>
                        <v-autocomplete
                          v-model="editrate_work_g.position"
                          :items="userstatus"
                          item-text="user_status_name"
                          item-value="user_status_name"
                          label="ตำแหน่ง"
                          required
                          :rules="[v => !!v || '']"
                        ></v-autocomplete>
                      </v-flex>
                    </v-flex>
                    <v-flex md12>
                      <v-text-field
                        label="กรณี"
                        v-model="editrate_work_g.case_vacancy"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="editrate_work_gdialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editrate_work_gSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",
      years_select: "",
      year_ss: [2565, 2566, 2567, 2568, 2569, 2570],
      valid: true,
      addrate_work_gdialog: false,
      editrate_work_gdialog: false,
      deleterate_work_gdialog: false,
      deleterate_work_gPersondialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      rate_work_gs: [],
      addrate_work_g: {},
      editrate_work_g: {},
      colleges: [],
      userstatus: [],
      search: "",
      pagination: {},
      headers: [      
        { text: "สถานะ", align: "left", value: "rate_work_g_status" },
        { text: "ยกเลิกรายงาน", align: "left", value: "action_s" },
        {
          text: "รหัสสถานศึกษา",
          align: "left",
          value: "rate_work_g_college_code"
        },
        { text: "สถานศึกษา", align: "left", value: "college_name" },
        { text: "จังหวัด", align: "left", value: "province_name" },
        { text: "ปี", align: "left", value: "rate_work_g_year" },

        {
          text: "ประเภท",
          align: "left",
          value: "college_id_code_type_manpower"
        },
        { text: "ประเภท", align: "left", value: "type_college" },
        { text: "นร.ปวช.", align: "left", value: "std_vc" },
        { text: "นศ.ปวส.", align: "left", value: "std_hvc" },
        { text: "นศ.ระยะสั้น", align: "left", value: "std_sc" },

        { text: "ห้องรวม", align: "left", value: "rate_room_cal_all" },
        { text: "เกณฑ์รอง", align: "left", value: "rate_criterion_sedr" },

        { text: "รวม เกณฑ์", align: "left", value: "rate_criterion_all" },
        {
          text: "ผอ. -+",
          align: "left",
          value: "rate_criterion_incomplete_dr"
        },
        {
          text: "รอง. -+",
          align: "left",
          value: "rate_criterion_incomplete_sedr"
        },
        {
          text: "ครู -+",
          align: "left",
          value: "rate_criterion_incomplete_teach_all"
        },
        {
          text: "สนับสนุน -+",
          align: "left",
          value: "rate_work_g_sup_incomplete"
        },
        {
          text: "รวม -+",
          align: "left",
          value: "rate_criterion_incomplete_all"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],

      college: {},
      provinces: [],
      prefectures: [],
      collgegs: [],
      rate_work_gstatus: [],
      regions: [],
      region_ena: true,
      selectedItem: 1,
      items: [
        {
          text: "รายงานข้อมูลสถานศึกษา",
          icon: "mdi-clock",
          link: "#/admin/print_report_rate_work_g/"
        },
        {
          text: "รายงานอัตรากำลังครูผู้สอน",
          icon: "mdi-clock",
          link: "#/admin/print_report_rate_work_g_teach/"
        },
        {
          text: " รายงานอัตรากำลัง ผู้บริหาร",
          icon: "mdi-clock",
          link: "#/admin/print_report_rate_work_g_dr/"
        },
        {
          text: "รายงานอัตรากำลังสายสนับสนุนการสอน",
          icon: "mdi-clock",
          link: "#/admin/print_report_rate_work_g_sup/"
        },
        {
          text: "รายงานอัตรากำลังลูกจ้างทั่วไป/ลูกจ้างสายการสอน",
          icon: "mdi-clock",
          link: "#/admin/print_report_rate_work_g_pte/"
        }
      ],
      itemsStatus: [
        {
          text: "สถานศึกษาดำเนินการเรียบร้อย",
          icon: "mdi-checkbox-marked-circle",
          link: "#/admin/rate_work_gConfirm/"
        },
        {
          text: "สถานศึกษาอยู่ระหว่างดำเนินการ",
          icon: "mdi-alert-circle",
          link: "#/admin/rate_work_gSave/"
        },
        {
          text: "สถานศึกษาไม่ดำเนินการ",
          icon: "mdi-alert-outline",
          link: "#/admin/rate_work_gNull/"
        }
      ],

      updaterate_work_college: {}, //Update Confirm > Null
      deleterate_work_college_cal_room: {}, //Clear Cal room
      updaterate_work_g: {}, // Update rate_work_g Confirm > Null
      updaterate_work_sc: {}, //Update rate_work_sc Confirm > Null
      successReport: [],
      workingReport: [],
      dontworkingReport: []
    };
  },
  async mounted() {},

  methods: {
    async rate_work_gQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("rate_work_g.php", {
          ApiKey: this.ApiKey
        })
        .finally(() => (this.loading = false));
      this.rate_work_gs = result.data;
    },

    async rate_work_gQueryTime() {
      this.loading = true;
      let result = await this.$http
        .post("rate_work_g.php", {
          ApiKey: this.ApiKey,
          rate_work_g_year: this.years_select
        })
        .finally(() => (this.loading = false));
      this.rate_work_gs = result.data;
    },

    async checkConfirm() {
      this.loading = true;
      let result = await this.$http
        .post("rate_work_g.php", {
          ApiKey: this.ApiKey,
          rate_work_g_year: this.years_select,
          checkConfirm: "OK"
        })
        .finally(() => (this.loading = false));
      this.rate_work_gs = result.data;
    },

    async checkSave() {
      this.loading = true;
      let result = await this.$http
        .post("rate_work_g.php", {
          ApiKey: this.ApiKey,
          rate_work_g_year: this.years_select,
          checkSave: "OK"
        })
        .finally(() => (this.loading = false));
      this.rate_work_gs = result.data;
    },

    async checkNull() {
      this.loading = true;
      let result = await this.$http
        .post("rate_work_g.php", {
          ApiKey: this.ApiKey,
          checkNull: "OK"
        })
        .finally(() => (this.loading = false));
      this.rate_work_gs = result.data;
    },

    async rate_work_gAdd() {
      this.addrate_work_g = {};
      this.addrate_work_gdialog = true;
    },

    async rate_work_gEdit(rate_work_g_year, rate_work_g_college_code) {
      let result = await this.$http.post("rate_work_g.php", {
        ApiKey: this.ApiKey,
        rate_work_g_year: rate_work_g_year,
        rate_work_g_college_code: rate_work_g_college_code,
        rate_work_g_year_admin: "Check"
      });
      this.editrate_work_g = result.data;
      this.editrate_work_gdialog = true;
    },

    async rate_work_gCancelPerson(rate_work_g_year, rate_work_g_college_code) {
      let result = await this.$http.post("rate_work_g.php", {
        ApiKey: this.ApiKey,
        rate_work_g_year: rate_work_g_year,
        rate_work_g_college_code: rate_work_g_college_code,
        rate_work_g_year_admin: "Check"
      });
      this.editrate_work_g = result.data;
      this.deleterate_work_gPersondialog = true;
    },

    async rate_work_gDelete(rate_work_g_year, rate_work_g_college_code) {
      let result = await this.$http.post("rate_work_g.php", {
        ApiKey: this.ApiKey,
        rate_work_g_year: rate_work_g_year,
        rate_work_g_college_code: rate_work_g_college_code,
        rate_work_g_year_admin: "Check"
      });
      this.editrate_work_g = result.data;
      this.deleterate_work_gdialog = true;
    },

    async rate_work_gDelete(rate_work_g_year, rate_work_g_college_code) {
      let result = await this.$http.post("rate_work_g.php", {
        ApiKey: this.ApiKey,
        rate_work_g_year: rate_work_g_year,
        rate_work_g_college_code: rate_work_g_college_code,
        rate_work_g_year_admin: "Check"
      });
      this.editrate_work_g = result.data;
      this.deleterate_work_gdialog = true;
    },

    async deleterate_work_gSubmit() {
      if (this.$refs.deleterate_work_gform.validate()) {
        this.editrate_work_g.ApiKey = this.ApiKey;

        this.updaterate_work_college.ApiKey = this.ApiKey;
        this.updaterate_work_college.rate_work_college_year = this.editrate_work_g.rate_work_g_year;
        this.updaterate_work_college.rate_work_college_code = this.editrate_work_g.college_code;
        this.updaterate_work_college.rate_work_college_status = "";

        this.deleterate_work_college_cal_room.ApiKey = this.ApiKey;
        this.deleterate_work_college_cal_room.rate_work_ccr_year = this.editrate_work_g.rate_work_g_year;
        this.deleterate_work_college_cal_room.rate_work_ccr_code = this.editrate_work_g.college_code;

        this.updaterate_work_g.ApiKey = this.ApiKey;
        this.updaterate_work_g.rate_work_g_year = this.editrate_work_g.rate_work_g_year;
        this.updaterate_work_g.rate_work_g_college_code = this.editrate_work_g.college_code;

        this.updaterate_work_sc.ApiKey = this.ApiKey;
        this.updaterate_work_sc.rate_sc_year = this.editrate_work_g.rate_work_g_year;
        this.updaterate_work_sc.rate_sc_college_code = this.editrate_work_g.college_code;
        this.updaterate_work_sc.rate_sc_status = "";

        let resultrate_work_college = await this.$http.post(
          "rate_work_college.update.admin.php",
          this.updaterate_work_college
        );

        let resultdeleterate_work_college_cal_room = await this.$http.post(
          "rate_work_college_cal_room.admin.delete.php",
          this.deleterate_work_college_cal_room
        );

        let resultupdaterate_work_g = await this.$http.post(
          "rate_work_g.delete.php",
          this.updaterate_work_g
        );

        let resultupdaterate_work_sc = await this.$http.post(
          "rate_work_sc.update.admin.php",
          this.updaterate_work_sc
        );

        if (resultupdaterate_work_g.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการลบข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.rate_work_gQueryTime();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการลบผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.deleterate_work_gdialog = false;
      }
    },

    async deleterate_work_gPersonSubmit() {
      if (this.$refs.deleterate_work_gPersonform.validate()) {
        this.editrate_work_g.ApiKey = this.ApiKey;

        this.updaterate_work_college.ApiKey = this.ApiKey;
        this.updaterate_work_college.rate_work_college_year = this.editrate_work_g.rate_work_g_year;
        this.updaterate_work_college.rate_work_college_code = this.editrate_work_g.college_code;
        this.updaterate_work_college.rate_work_college_status = "";

        this.updaterate_work_g.ApiKey = this.ApiKey;
        this.updaterate_work_g.rate_work_g_year = this.editrate_work_g.rate_work_g_year;
        this.updaterate_work_g.rate_work_g_college_code = this.editrate_work_g.college_code;


        this.updaterate_work_sc.ApiKey = this.ApiKey;
        this.updaterate_work_sc.rate_sc_year = this.editrate_work_g.rate_work_g_year;
        this.updaterate_work_sc.rate_sc_college_code = this.editrate_work_g.college_code;
        this.updaterate_work_sc.rate_sc_status = "";

     
        let resultrate_work_college = await this.$http.post(
          "rate_work_college.update.admin.php",
          this.updaterate_work_college
        );

        let resultupdaterate_work_g = await this.$http.post(
          "rate_work_g.delete.php",
          this.updaterate_work_g
        );

        let resultupdaterate_work_sc = await this.$http.post(
          "rate_work_sc.update.admin.php",
          this.updaterate_work_sc
        );

        if (resultrate_work_college.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการลบข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.rate_work_gQueryTime();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการลบผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.deleterate_work_gPersondialog = false;
      }
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  }
};
</script>
